<template>
	<div class="chart-container" v-if="!currentQuarterTargetExceeded">
		<v-overlay
			:opacity="0.15"
			:value="loader"
			absolute
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="white"
						indeterminate
						size="40"
						width="6"
					/>
				</v-col>
			</v-row>
		</v-overlay>
		<v-card elevation="6" class="mx-auto mt-5 green darken-3">
			<v-card-text>
				<div class="error-container" v-if="loadingError">
					<v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
				</div>
				<div class="font-lg white--text text--darken-1">
					{{ label }}
					<v-icon class="mdi mdi-rocket-launch" size="20" color="white"/>
				</div>
				<v-simple-table dense class="green darken-3 max-commission mt-3" dark>
					<template v-slot:default>
						<tr>
							<th class="text-start font-md">{{ headerText1 }}</th>
							<th class="text-right font-md">{{ headerText2 }}</th>
						</tr>
						<tbody>
						<template>
							<tr v-for="potentialCommission in potentialCommissions">
								<td class="text-start">{{ '' + potentialCommission['exceedPct'] + '%' }}</td>
								<td class="text-right">{{ '$' + formatThisNumber(potentialCommission['commissionValue'],'0,0') }}</td>
							</tr>
						</template>
						</tbody>
					</template>
				</v-simple-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import {numberFormat} from "Helpers/helpers";
import {api} from "Api";
export default {
	name: "MaximumCommissionPotential",
    props: ['conditions'],
	data(){
		return {
			errorMessage: null,
			headerText1: '',
			headerText2: '',
			label: '',
			loader: true,
			loadingError: false,
			potentialCommissions: [],
			currentQuarterTargetExceeded: false
		}
	},
	methods: {
		formatThisNumber (value,format) {
			return numberFormat(value,format)
		},
		getValues() {
			return new Promise((resolve, reject) => {
				const url = '/sparkline/chart/gm-maximum-commission'
				if(url) {
					api
						.get(url, {
							params: {
								conditions: this.conditions ? this.conditions : []
							}
						})
						.then((response) => {
							const data = response.data.data
							resolve(data)
						})
						.catch((error) => {
							reject(error)
						})
				} else {
					reject('error')
				}
			})
		}
	},
	mounted() {
		this.getValues()
			.then((data) => {
				this.label = data.label
				this.headerText1 = data.headerText[0]
				this.headerText2 = data.headerText[1]
				this.potentialCommissions = data.potentialCommissions
				this.currentQuarterTargetExceeded = data.currentQuarterTargetExceeded
				this.currentQuarterMissingGrossMargin = data.currentQuarterMissingGrossMargin
				this.loader = false
			})
			.catch((error) => {
				this.loader = false
				this.loadingError = true
				this.errorMessage = error
			})
	}
}
</script>

<style scoped>
.chart-container {
	position: relative;
}
.max-commission tbody td {
    font-size: 20px !important;
}
.theme--light .v-icon {
	color: white !important;
}
</style>